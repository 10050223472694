<template>
  <div :class="['question',{'homeHidden':finish}]">
    <div class="main">
      <div class="top">
        <img class="logo" src="@/assets/AG-logo.png" alt />
        <h1>实习企业家调查问卷</h1>
      </div>
      <!-- 表单 -->
      <el-form ref="form" :model="form" :rules="rules" @submit.native.prevent="submitForm">
        <el-form-item label="姓名" prop="name">
          <el-input v-model="form.name" placeholder="请输入您的姓名" prop="name"></el-input>
        </el-form-item>
        <div v-for="(item, moduleIndex) in modules" :key="moduleIndex">
          <div class="moduleTitle">{{item.moduleTitle}}</div>
          <div class="questionBox">
            <div v-for="(question,index) in item.questions" :key="index">
              <el-form-item
                label-position="top"
                :rules="{ required: true, validator: validateState, trigger: 'change', immediate: true }"
                :label="`${index + 1}. ${question.question}`"
                :prop="`data.${moduleIndex+1}-${index}`"
              >
                <el-radio-group
                  v-if="question.answer"
                  v-model="form.data[`${moduleIndex+1}-${index}`]"
                >
                  <el-radio
                    v-for="(ans,ansIndex) in question.answer"
                    :label="ansIndex+1"
                    :key="ansIndex"
                  >{{ans}}</el-radio>
                </el-radio-group>
                <el-radio-group v-else v-model="form.data[`${moduleIndex+1}-${index}`]">
                  <el-radio :label="1">持续投入全部的努力</el-radio>
                  <el-radio :label="2">经常</el-radio>
                  <el-radio :label="3">偶尔</el-radio>
                  <el-radio :label="4">几乎不能</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
          </div>
        </div>
        <el-button type="primary" class="button" @click="validateForm">提交</el-button>
      </el-form>
    </div>
    <!-- 提交成功遮罩层 -->
    <div class="mask" v-if="finish">
      <div class="box">
        <img class="bingo" src="@/assets/finish.png" alt />
        <div class="text">提交成功</div>
      </div>
    </div>
  </div>
</template>


<script>
import { send } from '@/api/index.js'
import { getShareInfo } from '@/untils/share'
export default {
  name: 'HomeView',
  data() {
    return {
      form: {
        data: {},
        name: ''
      },
      finish: false,
      rules: {
        name: [{ required: true, message: '姓名不能为空', trigger: 'blur' }]
      },
      modules: [
        {
          moduleTitle: '一、企业家作为事业的启动者',
          questions: [
            {
              question: '机会识别能力'
            },
            {
              question: '市场需求和供给信息的整合能力'
            },
            {
              question: '敏锐地察觉到顾客的潜在需求'
            },
            {
              question: '获取市场相关信息'
            },
            {
              question: '主动寻找可为顾客带来实际利益的新产品或服务'
            },
            {
              question: '从零散的市场信息中形成商业模式的能力'
            }
          ]
        },
        {
          moduleTitle: '二、战略管理能力作为经营者/组织者',
          questions: [
            {
              question: '面对不确定性的能力'
            },
            {
              question: '运用适应性战略管理开发新商业创意过程'
            },
            {
              question: '制定一个有效的成长战略的能力'
            },
            {
              question: '市场需求增长管理'
            },
            {
              question: '竞争态势变化管理'
            },
            {
              question: '自身资源基础能力'
            }
          ]
        },
        {
          moduleTitle: '三、组织管理能力作为管理者',
          questions: [
            {
              question: '组织和有效利用各种内外部人力资源'
            },
            {
              question: '有效授权的能力'
            },
            {
              question: '体系化监测预警和识别分享的能力'
            },
            {
              question: '组建高效运作的管理团队的能力'
            },
            {
              question: '构建有效的组织机构协作的能力'
            },
            {
              question: '领导和激励员工的能力'
            }
          ]
        },
        {
          moduleTitle: '四、创新能力',
          questions: [
            {
              question:
                '引领和促进企业的技术创新、产品创新、商业模式创新和管理创新的能力'
            },
            {
              question: '通过创新实现业务的差异化定位的能力'
            },
            {
              question: '获得独特的竞争优势，建立起企业动态竞争能力'
            },
            {
              question: '机会识别能力'
            }
          ]
        },
        {
          moduleTitle: '五、构建和管理利益相关方的能力',
          questions: [
            {
              question:
                '与客户、供应商、雇员构建符合实事求是原则的关系与协调、调整的能力'
            },
            {
              question:
                '与相关行业上下游潜在合作机构建立良好关系与协调、调整的能力'
            }
          ]
        },
        {
          moduleTitle: '六、学习能力',
          questions: [
            {
              question: '善于从实践中学习'
            },
            {
              question: '善于向他人学习力'
            },
            {
              question: '能够快速地更新知识结构'
            },
            {
              question: '善于总结经验教训，能够做到学以致用、不断实现自我超越'
            },
            {
              question: '不断学习和自我突破的能力'
            }
          ]
        },
        {
          moduleTitle: '七、财务能力',
          questions: [
            {
              question: '降低风险'
            },
            {
              question: '优化企业成本增利润'
            },
            {
              question: '盈利稳步增长'
            },
            {
              question: '财务安全'
            }
          ]
        },
        {
          moduleTitle: '八、企业创立和发展的动机',
          questions: [
            {
              question: '我对所开启的项目的动机是来自',
              answer: ['我自己的道路', '行善', '名利', '不得不']
            },
            {
              question: '对于实习项目开启的信心',
              answer: ['绝对的信心 ', '相信', '审慎的乐观', '担心不确定性']
            },
            {
              question: '对于实习项目过程的期待',
              answer: ['迫不及待 ', '非常期待', '期待', '无感']
            },
            {
              question: '对于实习成果的确信',
              answer: ['绝对的信心 ', '相信', '审慎的乐观', '担心不确定性']
            }
          ]
        }
      ],
      // 当前终端
      isMobile: null
    }
  },
  created() {
    const selectedIdsString = this.$route.query.selectedIds
    if (selectedIdsString) {
      this.form.select = selectedIdsString
      console.log(this.form)
    }
  },
  mounted() {
    getShareInfo()
    this.isMobile = this.isMobileDevice()
  },
  methods: {
    // 自定义表单校验
    isEmpty(arg) {
      if (typeof arg === 'undefined' || arg === null || arg === '') {
        return true
      }
      return false
    },
    // 自定义表单校验
    validateState(rule, value, callback) {
      if (this.isEmpty(value)) {
        return callback(new Error('请选择'))
      }
      return callback()
    },
    // 遮罩层显示禁止页面滚动
    toggleBodyScroll(enable) {
      if (enable) {
        document.body.style.overflow = 'auto' // 恢复滚动
      } else {
        document.body.style.overflow = 'hidden' // 禁止滚动
      }
    },
    // 遮罩层展示
    showMask() {
      this.finish = true
      this.toggleBodyScroll(false)
      setTimeout(() => {
        this.finish = false
        this.toggleBodyScroll(true)
      }, 2000)
    },
    // 提交表单
    validateForm() {
      var data = this.form.data
      this.form.questionnaireData = JSON.stringify(data)
      this.$refs.form.validate((valid) => {
        if (valid) {
          send(this.form).then((res) => {
            const { code, message } = res
            if (code == 200) {
              // 移动端出现遮罩层提示
              if (this.isMobile) {
                this.showMask()
              } else {
                // 非移动端toast提示
                this.$message.success('提交成功')
              }
            } else {
              this.$message.error(message)
            }
          })
        } else {
          this.$message.warning('请检查题目，填写完整')
          return false
        }
      })
    },
    isMobileDevice() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera
      // 正则表达式检查是否为iOS、Android 或 Windows Phone
      return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
        userAgent
      )
    }
  }
}
</script>
<style scoped lang='scss'>
@import './index.scss';

::v-deep .el-radio {
  width: 100%;
  margin-top: 10px;
  padding-left: 15px;
  box-sizing: border-box;
}

::v-deep .el-form-item__label {
  width: 100%;
  line-height: 30px;
  margin-bottom: 10px;
  font-family: PingFangSC, PingFang SC;
  font-weight: bold;
  font-size: 18px;
  color: #3b3f4c;
  text-align: left; /* 确保标签文本左对齐 */
  display: inline-block; /*行 使标签内文本可换 */
  white-space: normal; /* 允许文本换行 */
  line-height: 1.5; /* 调整行高以改善可读性 */
  text-indent: -1em; /* 第一行之后的行缩进 */
  padding-left: 1em; /* 对整个标签内容添加左填充以对齐 */
}
::v-deep .el-radio__label {
  font-family: PingFangSC, PingFang SC;
  color: #3b3f4c;
  font-size: 16px;
}
::v-deep .el-radio__input.is-checked .el-radio__inner {
  border-color: #009a97;
  background: #009a97;
}
::v-deep .el-radio__input.is-checked + .el-radio__label {
  color: #3b3f4c;
}
::v-deep .el-radio__inner:hover {
  border-color: #009a97;
}
::v-deep .el-button--primary {
  margin-top: 30px;
  border-color: #009a97;
  background: #009a97;
  font-size: 16px;
  width: 100px;
  height: 44px;
}
::v-deep .el-button--primary:focus,
.el-button--primary:hover {
  border-color: #009a97;
  background: #009a97;
}
::v-deep .el-button--primary:hover {
  border-color: #009a97;
  background: #009a97;
}
::v-deep .el-input__inner:focus {
  border-color: #009a97;
}
::v-deep
  .el-form-item.is-required:not(.is-no-asterisk)
  > .el-form-item__label:before {
  color: #f49334;
  content: '';
}
::v-deep .el-form-item__error {
  left: 15px;
}
::v-deep .el-input__inner::placeholder {
  font-size: 16px;
}
::v-deep .el-radio__inner {
  background-color: transparent;
}
/* 移动端 screen<768*/
@media screen and (max-width: 768px) {
  ::v-deep .el-form-item__label {
    font-size: 16px;
  }
  ::v-deep .el-radio__label {
    font-size: 14px;
  }
  ::v-deep .el-button--primary {
    width: 100%;
  }
}
</style>
